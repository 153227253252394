import React, { useState } from 'react';

const StaffingSolutions = () => {
  return (
    <section
      className="text-5xl text-bold h-[100vh] flex items-center justify-center"
      style={{ fontFamily: 'Poppins, sans-serif' }}
    >
      Coming Soon
    </section>
  );
};

export default StaffingSolutions;
